<template>
  <div v-if="visible">
    <div
      id="offcanvasQuickLinks"
      class="offcanvas offcanvas-end offcanvas-end--filters d-flex"
      :class="{ show: visible }"
      :style="{ visibility: visible ? 'visible' : 'hidden' }"
      tabindex="-1"
      aria-labelledby="offcanvasQuickLinksLabel"
    >
      <div class="offcanvas-header">
        <h3
          id="offcanvasQuickLinksLabel"
          class="offcanvas-title"
        >
          <span class="btn btn-icon btn-icon rounded-circle btn-flat-secondary bg-light-secondary me-2"><i data-feather="star" /></span> {{ favorite.id ? 'Edit' : 'Add' }} quick link
        </h3>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="closeOffcanva"
        />
      </div>
      <div class="offcanvas-body">
        <div class="mb-1">
          <label
            for="nameseniorcall"
            class="form-label"
          >Name</label>
          <input
            v-model="favorite.name"
            type="text"
            class="form-control"
            name="nameseniorcall"
          >
          <p><small class="text-muted">This is the name shown in the listing</small></p>
        </div>
        <div class="mb-1">
          <label
            for="nameseniorcall"
            class="form-label"
          >Link</label>
          <input
            v-model="favorite.link"
            type="text"
            class="form-control"
            name="nameseniorcall"
            disabled
          >
        </div>
      </div>
      <div class="offcanvas-footer mt-auto">
        <button
          type="button"
          class="btn btn-dark mb-1 d-grid w-100"
          @click="save"
        >
          {{ favorite.id ? 'Edit' : 'Save' }}
        </button>
      </div>
    </div>
    <div
      v-if="visible"
      class="offcanvas-backdrop fade"
      :class="{ show: visible }"
      @click="closeOffcanva"
    />
  </div>
  <!-- -->
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {},
  computed: {
    ...mapGetters({
      visible: 'modals/addFavorite',
      favorite: 'favorites/favorite',
    }),
  },
  watch: {
    visible() {
      if (this.visible && !this.favorite.id) {
        this.favorite.link = window.location.href
      }
    },
  },
  async mounted() {
    feather.replace({
      width: 14,
      height: 14,
    })
  },
  methods: {
    closeOffcanva() {
      this.$store.dispatch('modals/toggleAddFavorites', false)
    },
    save() {
      if (!this.favorite.id) {
        this.$store.dispatch('favorites/create', this.favorite)
      } else {
        this.$store.dispatch('favorites/update', this.favorite)
      }

      this.$store.dispatch('favorites/clearFavorite')
      this.$toastr.success('', 'Favorite saved successfully!')
      this.closeOffcanva()
    },
  },
}
</script>
