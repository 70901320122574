<template>
  <div>
    <div
      id="modalOTPValidation"
      class="modal fade modal-danger"
      tabindex="-1"
      aria-labelledby="modalOTPValidation"
      aria-hidden="true"
      :class="showModal ? 'show' : ''"
      :style="{ display: showModal ? 'block' : 'none' }"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              id="modalReportWithInput"
              class="modal-title"
            >
              Report an error
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeModal()"
            />
          </div>
          <div class="modal-body">
            <div
              class="mb-1"
            >
              <label
                class="form-label"
                for="inputTitleReport"
              >Title</label>
              <input
                v-model="reportData.reportTitle"
                type="text"
                class="form-control"
              >
            </div>
            <div
              class="mb-1"
            >
              <label
                class="form-label"
                for="inputReport"
              >Description</label>
              <quill-editor
                v-model="reportData.reportDescription"
                ref="quillReport"
              />
            </div>
          </div>
          <div class="modal-footer d-flex align-items-center justify-content-between">
            <button
              v-if="!loading"
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              @click="sendEmail()"
            >
              Send
            </button>
            <button
              v-if="loading"
              class="btn btn-outline-primary"
              type="button"
              disabled=""
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
              <span class="ms-25 align-middle">Sending...</span>
            </button>
            <button
              type="button"
              class="btn btn-flat-danger"
              data-bs-dismiss="modal"
              :disabled="loading"
              @click="closeModal()"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showModal"
      class="offcanvas-backdrop fade"
      :class="showModal ? 'show' : ''"
      @click="closeModal()"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  components: {},
  data() {
    return {
      showModal: false,
      reportTitle: '',
      reportDescription: '', 
      loading: false,
      errors: null,
    }
  },
  computed: {
    ...mapGetters({
      show: 'modals/reportModal',
      reportData: 'modals/reportData',
      loggedUser: 'auth/admin',
    }),
  },
  watch: {
    show() {
      this.errors = null
      this.toggleModal()
    },
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal
    },
    closeModal() {
      this.$store.dispatch('modals/showReportModal', false)
    },
    async sendEmail() {
      if (this.$refs.quillReport.quill.getLength() - 1 > 0) {
        this.loading = true
        const resp = await this.$store.dispatch('auth/sendReport', { id: this.loggedUser.id, data: this.reportData })
        if(resp.status === 'error') {
          Vue.swal('Error sending report', '', 'error')
        } else {
          Vue.swal('Report sent', '', 'success')
          this.closeModal()
        }
        this.loading = false
      } else {
        Vue.swal('Please fill up the description field', '', 'warning')
      }
    },
  },
}
</script>
