<template>
  <div>
    <div
      id="modalDeleteWithInput"
      class="modal fade modal-danger"
      tabindex="-1"
      aria-labelledby="modalDeleteWithInputTitle"
      aria-hidden="true"
      :class="showModal ? 'show' : ''"
      :style="{ display: showModal ? 'block' : 'none' }"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              id="modalDeleteWithInputTitle"
              class="modal-title"
            >
              Warning
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeModal()"
            />
          </div>
          <div class="modal-body">
            <h4 v-if="title != 'user'">
              The {{ title }} you are trying to delete has associated data
            </h4>
            <div class="mb-1">
              <label
                class="form-label"
                for="inputDelete"
              >Select a new {{ title }} to transfer data</label>
              <v-select
                v-if="items"
                v-model="selectedItem"
                label="name"
                :options="items.filter(o => o.id != itemId)"
                :get-option-key="option => option.id"
                @search="onSearch({ name: $event }, itemsFetchName)"
              />
            </div>
          </div>
          <div class="modal-footer d-flex align-items-center justify-content-between">
            <button
              type="button"
              class="btn btn-success"
              data-bs-dismiss="modal"
              :disabled="!selectedItem"
              @click="acceptModal()"
            >
              Confirm
            </button>
            <button
              v-if="!reorganizeData.removeDelete"
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
              @click="forceDelete()"
            >
              Force delete
            </button>
            <button
              type="button"
              class="btn btn-flat-danger"
              data-bs-dismiss="modal"
              @click="closeModal()"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showModal"
      class="offcanvas-backdrop fade"
      :class="showModal ? 'show' : ''"
      @click="closeModal()"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      // LOCAL VARIABLES
      showModal: false,
      selectedItem: null,
      errors: '',
      // GENERAL VARIABLES
      itemId: null,
      title: '',
      // DATA FOR DELETE BEFORE REORGANIZE
      hasRelationDispatch: {},
      deleteUrl: '',
      deleteFetchName: '',
      deleteCustomMessage: '',
      // DATA FOR REORGANIZE AFTER DELETE
      itemsFetchName: '',
      itemsStoreName: '',
    }
  },
  computed: {
    items() {
      return this.$store.getters[this.itemsStoreName]
    },
    reorganizeData() {
      return this.$store.getters['modals/reorganizeData']
    },
    show() {
      return this.$store.getters['modals/reorganizeModal']
    },
    hasRelatedData() {
      return this.$store.getters[`${this.deleteFetchName}/hasRelatedData`]
    },
  },
  watch: {
    show() {
      this.selectedItem = null
      this.toggleModal()
    },
    reorganizeData() {
      // GENERAL
      this.itemId = this.reorganizeData.itemId
      this.title = this.reorganizeData.title
      // DATA FOR DELETE BEFORE REORGANIZE
      this.hasRelationDispatch = this.reorganizeData.hasRelationDispatch
      this.deleteUrl = this.reorganizeData.deleteUrl
      this.deleteFetchName = this.reorganizeData.deleteFetchName
      this.deleteCustomMessage = this.reorganizeData.deleteCustomMessage
      // DATA FOR REORGANIZE AFTER DELETE
      this.itemsStoreName = this.reorganizeData.itemsStoreName
      this.itemsFetchName = this.reorganizeData.itemsFetchName
    },
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal
    },
    closeModal() {
      this.$store.dispatch('modals/showReorganizeModal', false)
    },
    async onSearch(search, store) {
      if (search.name !== '' && search.name.length > 2) {
        this.$store.dispatch('pagination/clearPage')
        await this.$store.dispatch(store, search)
      }
    },
    async acceptModal() {
      try {
        await this.$store.dispatch(`${this.deleteFetchName}/transferData`, { id: this.itemId, destinationId: this.selectedItem.id })
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '') {
        if (this.hasRelationDispatch.id) {
          await this.$store.dispatch(this.hasRelationDispatch.name, this.hasRelationDispatch.id)
        }
        if (!this.hasRelatedData) {
          Vue.swal('Data transfered!', '', 'success').then(async () => {
            await this.$store.dispatch(`${this.deleteFetchName}/fetch`, this.itemId)
            await this.$store.dispatch('modals/showReorganizeModal', false)
            await this.$store.dispatch('modals/saveDeleteData', { name: this.deleteFetchName, url: this.deleteUrl, customMessage: this.deleteCustomMessage })
            this.$store.dispatch('modals/showDeleteModal', true)
          })
        } else {
          Vue.swal('Error transferring data', '', 'error').then(async () => {
            await this.$store.dispatch(`${this.deleteFetchName}/fetch`, this.itemId)
            await this.$store.dispatch('modals/showReorganizeModal', false)
          })
        }
      }
    },
    async forceDelete() {
      await this.$store.dispatch('modals/showReorganizeModal', false)
      await this.$store.dispatch('modals/saveDeleteData', { name: this.deleteFetchName, url: this.deleteUrl })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
  },
}
</script>
