<template>
  <div>
    <div
      class="offcanvas offcanvas-end offcanvas-end--filters d-flex"
      :class="{ show: visible }"
      :style="{ visibility: visible ? 'visible' : 'hidden' }"
      tabindex="-1"
      aria-labelledby="offcanvasFiltersLabel"
    >
      <div class="offcanvas-header">
        <h3
          id="offcanvasResearchLineLabel"
          class="offcanvas-title"
        >
          <span class="btn btn-icon btn-icon rounded-circle btn-flat-secondary bg-light-secondary me-2"><i data-feather="settings" /></span>
          Select a request form
        </h3>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="closeOffcanva"
        />
      </div>
      <div class="offcanvas-body">
        <div
          class="mb-1"
        >
          <label
            for="areaseniorcall"
            class="form-label"
          >Request form</label>
          <v-select
            v-model="form"
            label="title"
            :options="forms"
            :get-option-key="option => option.id"
            placeholder="Type to search..."
          />
        </div>
      </div>
      <div class="offcanvas-footer mt-auto">
        <a
          href="#"
          title="apply actions"
          class="btn btn-primary mb-1 d-grid w-100"
          @click="applyActions"
        >Assign</a>
      </div>
    </div>
    <div
      v-if="visible"
      class="offcanvas-backdrop fade"
      :class="{ show: visible }"
      @click="closeOffcanva"
    />
  </div>
  <!-- -->
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  components: {},
  props: {
    // tableId: { type: Number, required: false, default: null },
  },
  data() {
    return {
      form: null,
    }
  },
  computed: {
    ...mapGetters({
      visible: 'modals/addRequestModal',
      forms: 'requestForms/items',
      attached: 'requestForms/attached',
    }),
  },
  watch: {
    attached() {
      this.form = this.attached
    },
  },
  mounted() {
    this.$store.dispatch('requestForms/fetch', false)
  },
  methods: {
    closeOffcanva() {
      this.$store.dispatch('modals/toggleRequestModal', false)
    },
    applyActions() {
      if (this.form && this.form.id) {
        Vue.swal({
          title: 'You are adding an extra form to this type of content that expands the information that can be added',
          html: 'If once added it is deleted or changed, the related data will be lost. You can modify the form and your changes will be reflected instantly in the associated content type',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes',
        }).then(async result => {
          if (result.isConfirmed) {
            await this.$store.dispatch('requestForms/attach', { type: this.visible, form: this.form })
            Vue.swal('Request form attached successfully', '', 'success')
            this.closeOffcanva()
          }
        })
      } else {
        Vue.swal('Select a request form', 'You need to select one to proceed', 'error')
      }
    },
  },
}
</script>
