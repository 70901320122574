<template>
  <div>
    <li class="nav-item d-none d-lg-block">
      <b-dropdown
        variant="link"
        no-caret
        toggle-class="p-0 btn btn-flat-dark dropdown-toggle waves-effect"
      >
        <template #button-content>
          <span
            class="dis-ib"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Quicks links"
          ><i
            data-feather="star"
          /></span>
        </template>
        <b-dropdown-item
          v-for="favorite in favorites"
          :key="favorite.id"
          :href="favorite.link"
          target="_blank"
        >
          {{ favorite.name }}
        </b-dropdown-item>
        <div class="dropdown-divider" />
        <b-dropdown-item @click="$router.push({name: 'favorites'})">
          <i
            data-feather="edit-3"
            class="me-50"
          /> Edit quick links
        </b-dropdown-item>
        <div class="dropdown-divider" />
        <b-dropdown-item @click="openAddFavorite">
          <i
            data-feather="plus"
            class="me-50"
          /> Add quick link
        </b-dropdown-item>
      </b-dropdown>
    </li>
  </div>

</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      user: null,
    }
  },
  computed: {
    ...mapGetters({
      favorites: 'favorites/favorites',
    }),
  },
  async mounted() {
    if (this.favorites.length === 0) {
      await this.$store.dispatch('favorites/fetch')
    }
  },
  methods: {
    openAddFavorite() {
      this.$store.dispatch('favorites/clearFavorite')
      this.$store.dispatch('modals/toggleAddFavorites', true)
    },
  },
}
</script>
