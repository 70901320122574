<template>
  <div>
    <!-- BEGIN: Header-->
    <nav
      class="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow container-xxl"
    >
      <div
        class="navbar-container d-flex"
        style="padding:0;padding-left: 1rem;"
      >
        <div class="bookmark-wrapper d-flex align-items-center">
          <ul class="nav navbar-nav d-xl-none">
            <li class="nav-item">
              <a
                class="nav-link menu-toggle"
                @click="$store.dispatch('modals/toggleEvaluatorMenu', true)"
              ><i
                class="ficon"
                data-feather="menu"
              /></a>
            </li>
          </ul>
          <ul class="nav navbar-nav bookmark-icons">
            <li
              v-if="!hide"
              class="nav-item d-none d-lg-block"
            >
              <!-- <a class="nav-link" href="#" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Quick add"><i data-feather="plus-square"></i></a> -->

              <div class="btn-group">
                <button
                  id="dropdownMenuButton100"
                  class="btn btn-flat-dark dropdown-toggle waves-effect"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Quick add"
                  ><i
                    data-feather="plus-square"
                  /></span>
                </button>
                <div
                  class="dropdown-menu"
                  aria-labelledby="dropdownMenuButton100"
                >
                  <h6 class="dropdown-header">
                    Header 1
                  </h6>
                  <a
                    class="dropdown-item"
                    href="#"
                  >Add 1</a>
                  <a
                    class="dropdown-item"
                    href="#"
                  >Add 2</a>
                  <a
                    class="dropdown-item"
                    href="#"
                  >Add 3</a>
                  <div class="dropdown-divider" />
                  <h6 class="dropdown-header">
                    Header 2
                  </h6>
                  <a
                    class="dropdown-item"
                    href="#"
                  >Add 4</a>
                  <a
                    class="dropdown-item"
                    href="#"
                  >Add 5</a>
                </div>
              </div>
            </li>

            <AccessUsers v-if="user.roles.includes('super-admin')" />
            <Favorites />
            <Alerts />
            <a
              v-if="user.roles.includes('super-admin')"
              class="nav-link-search btn btn-icon btn-flat-dark"
              target="_blank"
              href="https://account.postmarkapp.com/servers/11216126/streams/outbound/events"
            >
              <i
                class=""
                data-feather="mail"
              />
            </a>
          </ul>

          <ul class="nav navbar-nav navbar-nav--divider">
            <li
              v-if="!hide"
              class="nav-item nav-search"
            >
              <a
                class="nav-link-search btn btn-icon btn-flat-dark"
              ><i
                class=""
                data-feather="search"
              /></a>
              <div class="search-input">
                <div class="search-select-filter">
                  <select
                    id=""
                    name=""
                    class="form-select"
                  >
                    <option value="">
                      All intranet
                    </option>
                    <option value="">
                      Researchers
                    </option>
                    <option value="">
                      Etc 1
                    </option>
                    <option value="">
                      Etc 2
                    </option>
                  </select>
                </div>
                <div class="search-input-icon">
                  <i data-feather="search" />
                </div>
                <input
                  class="form-control input"
                  type="text"
                  placeholder="Search in ICREA..."
                  tabindex="-1"
                  data-search="search"
                >
                <div class="search-input-close">
                  <i data-feather="x" />
                </div>
                <ul class="search-list search-list-main" />
              </div>
            </li>
            <li
              v-if="user.roles.includes('super-admin')"
              class="nav-item"
            >
              <a
                class="btn btn-icon btn-flat-dark text-danger d-flex"
                @click="showReportModal"
              >
                <i
                  data-feather="alert-triangle"
                /> <span class="my-auto ms-50">To report an error, click here</span>
              </a>
            </li>
            <li
              v-if="!changedPassword"
              class="nav-item"
            >
              <!-- <span class="badge bg-danger">You must to change the password</span> -->
              <a
                class="alert alert-danger custom-alert"
                @click="$router.push({name: 'researcher.edit-access'})"
              >
                You should change your password. Click here to change it
              </a>
            </li>
          </ul>
        </div>

        <ul class="nav navbar-nav align-items-center ms-auto">
          <li class="nav-item dropdown dropdown-user">
            <b-dropdown
              variant="link"
              no-caret
              toggle-class="p-0"
              right
            >
              <template #button-content>
                <a
                  id="dropdown-user"
                  class="nav-link dropdown-toggle dropdown-user-link"
                  href="#"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div class="user-nav d-sm-flex d-none">
                    <span class="user-name fw-bolder">{{ user.name }}</span>
                    <span class="user-status">Role{{ user.roles && user.roles.length > 1 ? 's' : '' }}: {{
                      user.roles ? user.roles.join(', ') : "Candidate"
                    }}</span>
                  </div>
                  <!-- <span class="avatar">
                    <img
                      class="round"
                      :src="
                        require('@/assets/images/portrait/small/avatar-s-11.jpg')
                      "
                      alt="avatar"
                      height="40"
                      width="40"
                    />
                  </span> -->
                </a>
              </template>

              <h6
                v-if="fakeUsers && fakeUsers.length > 0"
                class="dropdown-item-text"
              >
                Active accesses
                <a
                  @click="logoutFakeUser('all')"
                >
                  <i
                    class="me-50 mt-12 text-danger"
                    data-feather="power"
                  />
                </a>
              </h6>
              <b-dropdown-item v-if="fakeUsers && fakeUsers.length > 0 && user.roles[0] != 'super-admin'">
                <a @click="handleNewUser(null)">
                  <i
                    class="me-50"
                    data-feather="user"
                  /> Original User
                </a>
              </b-dropdown-item>
              <div v-if="user.roles.includes('super-admin')">
                <template v-for="user in fakeUsers">
                  <b-dropdown-item
                    v-if="user"
                    :key="user.id"
                  >
                    <a
                      v-if="user"
                      @click="handleNewUser(user.id)"
                    >
                      <i
                        class="me-50"
                        data-feather="user"
                      /> {{ user.name }}
                    </a>
                    <a
                      v-if="user"
                      @click="logoutFakeUser(user.id, user.name)"
                    >
                      <i
                        class="me-50 mt-12 text-danger"
                        data-feather="power"
                      />
                    </a>
                  </b-dropdown-item>
                </template>
              </div>

              <div
                v-if="fakeUsers && fakeUsers.length > 0"
                class="dropdown-divider"
              />

              <!-- <b-dropdown-item>
                <a>
                  <i
                    class="me-50"
                    data-feather="user"
                  /> Profile
                </a>
              </b-dropdown-item>
              <div class="dropdown-divider" />
              <b-dropdown-item>
                <a>
                  <i
                    class="me-50"
                    data-feather="mail"
                  /> Contact us
                </a>
              </b-dropdown-item>
              <b-dropdown-item>
                <a>
                  <i
                    class="me-50"
                    data-feather="settings"
                  /> Settings
                </a>
              </b-dropdown-item>
              <b-dropdown-item>
                <a>
                  <i
                    class="me-50"
                    data-feather="help-circle"
                  /> FAQ
                </a>
              </b-dropdown-item> -->
              <b-dropdown-item @click="logout">
                <a>
                  <i
                    class="me-50"
                    data-feather="power"
                  /> Logout
                </a>
              </b-dropdown-item>
            </b-dropdown>
          </li>
        </ul>
      </div>
    </nav>

    <ul class="main-search-list-defaultlist-other-list d-none">
      <li class="auto-suggestion justify-content-between">
        <a
          class="d-flex align-items-center justify-content-between w-100 py-50"
        >
          <div class="d-flex justify-content-start">
            <span
              class="me-75"
              data-feather="alert-circle"
            /><span>No results found.</span>
          </div>
        </a>
      </li>
    </ul>
    <!-- END: Header-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import AccessUsers from './components/AccessUsers.vue'
import Favorites from './components/Favorites.vue'
import Alerts from './components/Alerts.vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    AccessUsers,
    Favorites,
    Alerts,
  },
  data() {
    return {
      hide: true,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/admin',
      fakeUsers: 'auth/fakeUsers',
      changedPassword: 'modals/changedPassword',
    }),
  },
  async mounted() {
    // await this.$store.dispatch('modals/checkPassword', { userId: this.user.id })
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    async logout() {
      await this.$store.dispatch('auth/adminLogout')
      // Redirect to login page
      this.$router.push({ name: 'admin.login' })
    },
    handleNewUser(userId = null) {
      if (userId) {
        window.location.href = `${this.$router.currentRoute.path}?actAs=${userId}`
      } else {
        window.location.href = this.$router.currentRoute.path
      }
    },
    async logoutFakeUser(userId, userName) {
      if (userId === 'all') {
        await this.$store.dispatch('auth/removeAllUsers', userId)
        this.$toastr.success('', 'Logged out of all users successfully!')
      } else {
        this.$toastr.success('', `Logged out of user ${userName} successfully!`)
        await this.$store.dispatch('auth/fakeUserLogout', userId)
      }
    },
    async showReportModal() {
      await this.$store.dispatch('modals/saveReportData', { user_agent: window.navigator.userAgent, routeName: window.location.pathname, user_id: this.user.id })
      this.$store.dispatch('modals/showReportModal', true)
    },
  },
}
</script>
