<template>
  <!-- BEGIN: Main Menu-->
  <div>
    <div
      v-if="show"
      class="main-menu menu-fixed menu-accordion menu-shadow expanded menu-dark"
      :style="openMenu ? 'transform: translate3d(260px, 0, 0);width: 260px;left: -260px;opacity:1;' : ''"
      data-scroll-to-active="true"
    >
      <div class="navbar-header">
        <ul class="nav navbar-nav flex-row">
          <li class="nav-item me-auto">
            <a
              class="navbar-brand"
              href="#"
            >
              <img
                :src="require('@/assets/images/logo/logo-icrea-white-menu.svg')"
                alt="ICREA"
              >
            </a>
          </li>
          <li class="nav-item nav-toggle">
            <a
              class="nav-link modern-nav-toggle pe-0"
              data-bs-toggle="collapse"
            >
              <i
                class="d-block d-xl-none text-primary toggle-icon font-medium-4"
                data-feather="chevron-down"
              />
              <i
                class="d-none d-xl-block collapse-toggle-icon font-medium-4 text-primary"
                data-feather="disc"
                data-ticon="disc"
              />
            </a>
          </li>
        </ul>
      </div>
      <div class="shadow-bottom" />
      <div class="main-menu-content overflow-auto mb-5">
        <div style="position: relative;">
          <input
            v-model="filterName"
            class="form-control ms-1 w-90 mt-1"
            placeholder="Filter menu items here..."
            autocomplete="off"
            type="text"
            style="background-color: #333;border: 0;outline: 0;opacity: 1;color: white !important;"
          >
          <div @click="filterName = ''">
            <i
              style="position: absolute; right: 20px; top: 50%; transform: translateY(-50%);color:white;"
              data-feather="x"
            />
          </div>
        </div>
        <ul
          id="main-menu-navigation"
          class="navigation navigation-main"
          data-menu="menu-navigation"
        >
          <template v-for="menu, index in filteredMenu">
            <li
              v-if="menu.type"
              :key="menu.name"
              class="navigation-header"
            >
              <span data-i18n="Configuration">{{ menu.name }}</span>
            </li>
            <li
              v-else
              :key="menu.name"
              class="nav-item"
              :class="{'has-sub': menu.childrens}"
            >
              <a
                :id="`field-menu-${index}`"
                v-b-toggle="`collapse-menu-${index}`"
                class="d-flex align-items-center"
                @click="openRoute(menu.route, menu.params)"
              >
                <i :data-feather="menu.icon" /><span
                  class="menu-title text-truncate"
                >{{ menu.name }}</span>
              </a>
              <b-collapse
                v-if="menu.childrens"
                :id="`collapse-menu-${index}`"
                :visible="visibleAllSections"
              >
                <ul class="menu-content ps-2">
                  <li
                    v-for="children, childrenIndex in filteredChildrens(menu)"
                    :key="children.name"
                    :class="{'has-sub': children.childrens}"
                  >
                    <a
                      v-b-toggle="`collapse-menu-children-${childrenIndex}`"
                      class="d-flex align-items-center"
                      @click="openRoute(children.route, children.params)"
                    >
                      <i class="fake-circle" />
                      <span
                        class="menu-item text-truncate"
                        data-i18n="Traces"
                      >{{ children.name }}</span>
                    </a>
                    <b-collapse
                      v-if="children.childrens"
                      :id="`collapse-menu-children-${childrenIndex}`"
                    >
                      <ul class="menu-content">
                        <li
                          v-for="childrenItem in children.childrens"
                          :key="childrenItem.name"
                        >
                          <a
                            class="d-flex align-items-center"
                            @click="openRoute(childrenItem.route, childrenItem.params)"
                          >
                            <i class="fake-circle" />
                            <span
                              class="menu-item text-truncate"
                              data-i18n="Traces"
                            >{{ childrenItem.name }}</span>
                          </a>
                        </li>
                      </ul>
                    </b-collapse>
                  </li>
                </ul>
              </b-collapse>
              <b-tooltip
                v-if="menu.tooltip"
                placement="right"
                boundary="document"
                triggers="hover"
                :target="`field-menu-${index}`"
                :title="menu.tooltip"
              />
            </li>
          </template>
        </ul>
      </div>
    </div>
    <!-- END: Main Menu-->
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { BCollapse, BTooltip } from 'bootstrap-vue'
import Vue from 'vue'

export default {
  components: {
    BCollapse,
    BTooltip,
  },
  data() {
    return {
      convo: null,
      year: null,
      filterName: '',
      visibleAllSections: false,
      menus: [
        {
          name: 'Dashboard',
          route: 'admin.dashboard',
          icon: 'home',
        },
        {
          name: 'Notifications & Tasks',
          route: 'alerts',
          icon: 'bell',
        },
        {
          name: 'Calls',
          icon: 'message-square',
          childrens: [
            {
              name: 'ICREA call',
              route: 'admin.calls.senior.index',
            },
            {
              name: 'Promotion',
              route: 'admin.calls.promotion.index',
            },
            {
              name: 'Academia call',
              route: 'admin.calls.academy.index',
            },
          ],
        },
        // {
        //   name: 'University',
        //   icon: 'message-square',
        //   childrens: [
        //     {
        //       name: 'Dashboard',
        //       route: 'admin.university.index',
        //     },
        //   ],
        // },
        {
          name: 'Users',
          icon: 'user',
          childrens: [
            {
              name: 'All users',
              route: 'admin.users.index',
            },
            {
              name: 'Applicants',
              route: 'admin.calls.applicant.index',
            },
            {
              name: 'Awardees',
              route: 'admin.calls.academy_awarded.index',
            },
            {
              name: 'Evaluators',
              route: 'admin.calls.evaluators.index',
            },
            {
              name: 'ICREAs',
              route: 'admin.users.index',
              params: {
                role: 'researcher',
              },
            },
            {
              name: 'CAEs',
              route: 'admin.users.index',
              params: {
                role: 'cae',
              },
            },
          ],
        },
        {
          name: 'Administration',
          icon: 'database',
          childrens: [
            {
              name: 'Administrative data',
              route: 'researcher.administrative-data',
            },
            {
              name: 'Dedications',
              route: 'researcher.administration.dedications.index',
            },
            {
              name: 'Evaluator-host history',
              route: 'researcher.administration.evaluator-history.index',
            },
            {
              name: 'Host history',
              route: 'researcher.administration.host-history.index',
            },
            {
              name: 'Non-nationals',
              route: 'researcher.administration.residency-documentation.index',
            },
            {
              name: 'Other contracts',
              route: 'researcher.administration.other-contracts.index',
            },
            {
              name: 'Temporary leaves',
              route: 'researcher.administration.temporary-leaves.index',
            },
            {
              name: 'Sabbaticals',
              route: 'researcher.administration.sabbaticals.index',
            },
            {
              name: 'Salary History',
              route: 'researcher.administration.salary-history.index',
            },
            {
              name: 'Supplements',
              route: 'researcher.administration.supplements.index',
            },
            {
              name: 'Unpaid leaves of absence',
              route: 'researcher.administration.unpaid-leaves.index',
            },
          ],
        },
        {
          name: 'Host institutions',
          icon: 'home',
          childrens: [
            {
              name: 'Hosts Institutions list',
              route: 'admin.institutions.index',
            },
            {
              name: 'Contacts',
              route: 'admin.institution-contacts.index',
            },
          ],
        },
        {
          name: "ICREA's Profile",
          icon: 'message-square',
          childrens: [
            {
              name: 'Personal data, Biography & Research interest',
              route: 'researcher.personal-data.index',
            },
            {
              name: 'Research lines',
              route: 'researcher.research-lines.index',
            },
            {
              name: 'Education',
              route: 'researcher.education.index',
            },
            {
              name: 'Honours & awards',
              route: 'researcher.honours-awards.index',
            },
            {
              name: 'Professional background',
              route: 'researcher.professional-background.index',
            },
            {
              name: 'Postdoctoral positions',
              route: 'researcher.postdoctoral-positions.index',
            },
          ],
        },
        {
          name: 'Grants & Resources',
          icon: 'mail',
          route: 'researcher.grants.index',
        },
        {
          name: 'Technology transfer',
          icon: 'message-square',
          childrens: [
            {
              name: 'Industrial property',
              route: 'researcher.industrial-property.index',
            },
            {
              name: 'Spin-off companies',
              route: 'researcher.spin-offs.index',
            },
          ],
        },
        {
          name: 'Scientific production',
          icon: 'settings',
          childrens: [
            {
              name: 'Scientific Production Report',
              route: 'researcher.scientific-production-report.index',
            },
            {
              name: 'Scientific Production Report Stats',
              route: 'admin.scientific-production.scientific-production-stats.index',
            },
            {
              name: 'Publications',
              route: 'researcher.publications.index',
            },
            {
              name: 'Conferences & workshops',
              route: 'researcher.conferences.index',
            },
            {
              name: 'Courses and seminars',
              route: 'researcher.courses.index',
            },
            {
              name: 'Dissemination',
              route: 'researcher.disseminations.index',
            },
            {
              name: 'Managerial activities',
              route: 'researcher.managerial.index',
            },
            {
              name: 'PhD, Master Theses and TFG',
              route: 'researcher.phds.index',
            },
            {
              name: 'Stays of research',
              route: 'researcher.stays-research.index',
            },
          ],
        },
        {
          name: 'Other sections',
          icon: 'map',
          route: 'researcher.administration.other-sections.index',
        },
        {
          name: 'Traces',
          icon: 'pen-tool',
          route: 'admin.traces.index',
        },
        {
          name: 'Reports',
          icon: 'book-open',
          route: 'admin.reports.index',
        },
        {
          name: 'OSH',
          icon: 'database',
          childrens: [
            {
              name: 'CAE',
              childrens: [
                {
                  name: 'CAE Section',
                  route: 'cae.index',
                },
                {
                  name: 'CAE Alerts',
                  route: 'admin.cae.view-alerts',
                },
              ],
            },
            {
              name: 'OSH. Send documents',
              route: 'admin.labour-hazards.index',
            },
            {
              name: 'Insurance',
              route: 'admin.insurances.index',
            },
            {
              name: 'TSS Management',
              route: 'admin.tss-management.index',
            },
            {
              name: 'Change requests',
              route: 'admin.change-requests.index',
            },
          ],
        },
        {
          name: 'Memoir',
          icon: 'framer',
          childrens: [
            {
              name: 'Memoirs list',
              route: 'admin.memoir.index',
            },
            {
              name: 'Memoirs profiles',
              route: 'researcher.memoir-profile.index',
            },
            {
              name: 'Memoirs highlights',
              route: 'researcher.memoir-highlight.index',
            },
            {
              name: 'Memoirs exports',
              route: 'memoir-exports.index',
            },
          ],
        },
        {
          name: 'Own OSH',
          route: 'admin.occupational-hazards.index',
          icon: 'home',
        },
        {
          name: 'Payrolls',
          icon: 'dollar-sign',
          route: 'researcher.administration.payrolls.index',
          action: 'checkOtpCode',
        },
        {
          name: 'Funcionalities',
          type: 'header',
        },
        {
          name: 'Auto notifications',
          icon: 'user',
          route: 'admin.auto-notifications.index',
        },
        {
          name: 'Mail logger',
          icon: 'mail',
          route: 'admin.mail-logger.index',
        },
        {
          name: 'Request forms',
          icon: 'file-text',
          route: 'admin.request-forms.index',
        },
        {
          name: 'Knowledge base',
          icon: 'book-open',
          route: 'knowledge-base.index',
        },
        {
          name: 'Collaborators',
          icon: 'users',
          route: 'researcher.administration.collaborators.index',
        },
        {
          name: 'Quick links',
          icon: 'star',
          route: 'favorites',
        },
        {
          name: 'Mail templates',
          icon: 'mail',
          route: 'admin.mail-templates.index',
        },
        {
          name: 'Masters',
          icon: 'database',
          childrens: [
            {
              name: 'Activities',
              childrens: [
                {
                  name: 'Conferences - Type of participation',
                  route: 'admin.activity-types.index',
                },
                {
                  name: 'Dissemination - Type of participation',
                  route: 'admin.dissemination-types.index',
                },
                {
                  name: 'Managerial - Type of activity',
                  route: 'admin.managerial-types.index',
                },
                {
                  name: 'Mentoring - Roles',
                  route: 'admin.roles-phd.index',
                },
                {
                  name: 'Mentoring - Type of degree',
                  route: 'admin.types-phd.index',
                },
                {
                  name: 'Stay of Research - Type of stay',
                  route: 'admin.stay-research-types.index',
                },
                {
                  name: 'Teaching - Type of courses',
                  route: 'admin.course-types.index',
                },
              ],
            },
            {
              name: 'Areas & Groups',
              route: 'admin.areas-groups.index',
            },
            {
              name: 'Card types',
              route: 'admin.card-types.index',
            },
            {
              name: 'Contract types',
              route: 'admin.contract-types.index',
            },
            {
              name: 'Dedication types',
              route: 'admin.dedication-types.index',
            },
            {
              name: 'Education levels',
              route: 'admin.education-levels.index',
            },
            {
              name: 'ERC',
              route: 'admin.erc.index',
            },
            {
              name: 'Genders',
              route: 'admin.genres.index',
            },
            {
              name: 'Grants',
              childrens: [
                {
                  name: 'Positions',
                  route: 'admin.grants-positions.index',
                },
                {
                  name: 'Types of funding',
                  route: 'admin.types-of-funding.index',
                },
                {
                  name: 'Types of personnel',
                  route: 'admin.types-of-personnel.index',
                },
                {
                  name: 'Types of resource',
                  route: 'admin.types-of-resource.index',
                },
              ],
            },
            {
              name: 'Host institutions',
              childrens: [
                {
                  name: 'Department types',
                  route: 'admin.institution-departments.index',
                },
                {
                  name: 'Hosts types',
                  route: 'admin.institution-types.index',
                },
                {
                  name: 'Positions',
                  route: 'admin.institution-positions.index',
                },
              ],
            },
            {
              name: 'ICREA status',
              route: 'admin.icrea-status.index',
            },
            {
              name: 'Industrial property types',
              route: 'admin.industrial-property-parent-types.index',
            },
            {
              name: 'Insurance types',
              route: 'admin.insurance-types.index',
            },
            {
              name: 'Modal texts',
              route: 'admin.modal-texts.index',
            },
            {
              name: 'OSH Masters',
              childrens: [
                {
                  name: 'Hazards',
                  route: 'admin.ocupational-hazard.index',
                },
                {
                  name: 'OSH',
                  route: 'admin.ocupational-safety.index',
                },
                {
                  name: 'Workplaces',
                  route: 'admin.workplaces.index',
                },
              ],
            },
            {
              name: 'Payrolls version',
              route: 'admin.payroll-versions.index',
            },
            {
              name: 'Postdoctoral positions',
              route: 'admin.positions.index',
            },
            {
              name: 'Publications',
              childrens: [
                {
                  name: 'Stages',
                  route: 'admin.publication-stages.index',
                },
                {
                  name: 'Status',
                  route: 'admin.publication-status.index',
                },
                {
                  name: 'Types',
                  route: 'admin.publication-types.index',
                },
              ],
            },
            {
              name: 'Residency documentation',
              childrens: [
                {
                  name: 'Authorizations',
                  route: 'admin.residency-documentation.authorization.index',
                },
                {
                  name: 'Stages',
                  route: 'admin.residency-documentation.stage.index',
                },
              ],
            },
            {
              name: 'Status management',
              route: 'admin.status-management.index',
            },
            {
              name: 'Supplement types',
              route: 'admin.supplement-types.index',
            },
            {
              name: 'Temporary leave types',
              route: 'admin.temporary-leave-types.index',
            },
            {
              name: 'Trace typologies',
              route: 'admin.trace-typologies.index',
            },
            {
              name: 'Type of leaves',
              route: 'admin.type-of-leaves.index',
            },
          ],
        },
        {
          name: 'Configuration',
          type: 'header',
        },
        {
          name: 'System config',
          route: 'admin.system-config.index',
          icon: 'settings',
        },
        {
          name: 'Phrases',
          route: 'admin.phrases.index',
          icon: 'align-center',
        },
        {
          name: 'Roles',
          route: 'admin.roles.index',
          icon: 'users',
        },
        {
          name: 'Social networks',
          route: 'admin.social-network.index',
          icon: 'twitter',
        },
        {
          name: 'Texts managements',
          route: 'admin.section-labels.index',
          icon: 'file-text',
          tooltip: 'Texts managements',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      show: 'modals/showAdminMenu',
      openMenu: 'modals/showEvaluatorMenu',
      user: 'auth/admin',
    }),
    filteredMenu() {
      return this.menus.filter(e => {
        this.visibleAllSections = true
        setTimeout(() => {
          feather.replace({
            width: 14,
            height: 14,
          })
        }, 100)

        if (this.filterName) {
          return e.name.toLowerCase().includes(this.filterName.toLowerCase()) || (e.childrens && e.childrens.some(f => f.name.toLowerCase().includes(this.filterName.toLowerCase())))
        }
        this.visibleAllSections = false

        return true
      })
    },
  },
  watch: {
    show() {
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 100)
    },
  },
  async mounted() {
    this.year = new Date().getFullYear()
    const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/get-promotion-active`)
    this.convo = resp.data
  },
  methods: {
    async checkOtpCode(routeName) {
      await this.$store.dispatch('auth/fetchActiveCode', this.user.id)
      if (this.activeCode !== 'OK') {
        await this.$store.dispatch('modals/saveOtpData', { route: routeName, type: 'route' })
        this.$store.dispatch('modals/showOtpModal', true)
      } else {
        this.$router.push({ name: routeName })
      }
    },
    filteredChildrens(menu) {
      return menu.childrens.filter(e => e.name.toLowerCase().includes(this.filterName.toLowerCase()) || (e.childrens && e.childrens.some(f => f.name.toLowerCase().includes(this.filterName.toLowerCase()))))
    },
    openRoute(route, params) {
      if (route) {
        this.$router.push({ name: route, params })
      }
    },
    canSee(section) {
      return this.user.roles.includes('super-admin') || this.user.permissions.includes(`read ${section}`)
    },
  },
}
</script>
