<template>
  <div>
    <div
      v-if="activeCode != 'OK'"
      id="modalOTPValidation"
      class="modal fade modal-danger"
      tabindex="-1"
      aria-labelledby="modalOTPValidation"
      aria-hidden="true"
      :class="showModal ? 'show' : ''"
      :style="{ display: showModal ? 'block' : 'none' }"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5>
              Restricted section
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeModal()"
            />
          </div>
          <div class="modal-body">
            <h4>
              <template v-if="activeCode === 'Code expired'">
                Your one time password expired, we will send you an email with an one time password to access this section to: <br>
                {{ auxEmail }}
              </template>
              <template v-else>
                We will send you an email with an one time password to access this section to: <br>
                {{ auxEmail }}
              </template>
            </h4>
            <p
              v-if="errors"
              class="text-danger"
            >
              {{ errors }}
            </p>
            <div
              v-if="activeCode === 'sended' || sendedCode === 'sended'"
              class="mb-1"
            >
              <label
                class="form-label"
                for="inputDelete"
              >Mail sent. Type one time password</label>
              <input
                v-model="code"
                type="text"
                maxlength="6"
                class="form-control"
                :class="{'is-invalid': invalid}"
                name="inputDelete"
              >
              <div
                v-if="invalid"
                class="invalid-feedback"
              >
                Invalid code
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex align-items-center justify-content-between">
            <button
              v-if="!loading && (sendedCode !== 'sended' && activeCode !== 'sended')"
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              @click="sendEmail()"
            >
              Send
            </button>
            <button
              v-if="!loading && (sendedCode === 'sended' || activeCode === 'sended')"
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              @click="validateCode()"
            >
              Validate
            </button>
            <button
              v-if="!loading && (sendedCode === 'sended' || activeCode === 'sended')"
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              @click="sendEmail()"
            >
              Resend email
            </button>
            <button
              v-if="loading"
              class="btn btn-outline-primary"
              type="button"
              disabled=""
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
              <span class="ms-25 align-middle">Sending...</span>
            </button>
            <button
              type="button"
              class="btn btn-flat-danger"
              data-bs-dismiss="modal"
              :disabled="loading"
              @click="closeModal()"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showModal"
      class="offcanvas-backdrop fade"
      :class="showModal ? 'show' : ''"
      @click="closeModal()"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  components: {},
  data() {
    return {
      showModal: false,
      // auxEmail: '',
      emailSended: false,
      loading: false,
      code: '',
      invalid: false,
      errors: null,
    }
  },
  computed: {
    ...mapGetters({
      show: 'modals/otpModal',
      otpData: 'modals/otpData',
      loggedUser: 'auth/admin',
      activeCode: 'auth/activeCode',
      sendedCode: 'auth/sendedCode',
    }),
    auxEmail() {
      return this.ocultarEmail(this.loggedUser.email)
    },
  },
  watch: {
    show() {
      this.invalid = false
      this.code = ''
      this.errors = null
      this.toggleModal()
    },
  },
  async mounted() {
    // this.auxEmail = this.ocultarEmail(this.loggedUser.email)
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal
    },
    closeModal() {
      this.$store.dispatch('modals/showOtpModal', false)
    },
    async sendEmail() {
      this.loading = true
      await this.$store.dispatch('auth/sendOtp', { id: this.loggedUser.id, type: 'email' })
      if (this.sendedCode === 'sended') {
        this.loading = false
        this.emailSended = true
      } else {
        this.loading = false
        this.emailSended = false
        this.errors = 'Error sending mail'
      }
      Vue.swal('Email sent', 'Please check your inbox and/or your spam folder', 'success')
    },
    async validateCode() {
      this.loading = true
      const status = await this.$store.dispatch('auth/validateOtpCode', { id: this.loggedUser.id, code: this.code })
      if (status === 'Code OK') {
        // Poner Swat con un then que esconda el modal y haga push a index
        Vue.swal('One time password validated', '', 'success').then(async () => {
          this.loading = false
          if (this.otpData.type === 'route') {
            this.$router.push({ name: this.otpData.route })
          } else {
            window.location.reload()
          }
          this.closeModal()
        })
      } else {
        this.invalid = true
        this.loading = false
      }
    },
    ocultarEmail(email) {
      // Dividir el email en nombre de usuario y dominio
      const [nombreUsuario, dominio] = email.split('@')

      // Ocultar parte del nombre de usuario y dominio
      const nombreUsuarioOculto = nombreUsuario[0] + '*'.repeat(nombreUsuario.length - 2) + nombreUsuario.slice(-1)
      const dominioOculto = dominio[0] + '*'.repeat(dominio.length - 2) + dominio.slice(-1)

      // Formar el email oculto
      const emailOculto = `${nombreUsuarioOculto}@${dominioOculto}`

      return emailOculto
    },
  },
}
</script>
