<template>
  <!-- BEGIN: Main Menu-->
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow expanded menu-dark"
    data-scroll-to-active="true"
  >
    <div class="navbar-header">
      <ul class="nav navbar-nav flex-row">
        <li class="nav-item me-auto">
          <a
            class="navbar-brand"
            href="#"
          >
            <img
              :src="require('@/assets/images/logo/logo-icrea-white-menu.svg')"
              alt="ICREA"
            >
          </a>
        </li>
      </ul>
    </div>
    <div class="shadow-bottom" />
    <div class="main-menu-content overflow-auto mb-5">
      <ul
        id="main-menu-navigation"
        class="navigation navigation-main"
        data-menu="menu-navigation"
      >
        <li class="nav-item">
          <a
            class="d-flex align-items-center"
            @click="
              $router.push({
                name: 'awarded.profile',
              })
            "
          >
            <i data-feather="home" /><span
              class="menu-title text-truncate ms-1"
              data-i18n="Calls"
            >Profile</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="d-flex align-items-center"
            @click="
              $router.push({
                name: 'awarded.awardees-list',
              })
            "
          >
            <i data-feather="award" /><span
              class="menu-title text-truncate ms-1"
              data-i18n="Calls"
            >Awardees</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <!-- END: Main Menu-->
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      user: 'auth/admin',
    }),
  },
  mounted() {
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 100)
  },
}
</script>
